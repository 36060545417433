<template>
    <div class="container-fluid">
        <div class="row">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Novo fornecedor</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="new-user-info">
                        <form @submit.prevent="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="fname">Nome:</label>
                                    <input 
                                        type="text" 
                                        v-model="name" 
                                        class="form-control"
                                        :class="`form-control ${$v.name.$error ? 'is-invalid' : ''}`" 
                                        id="name" 
                                        placeholder="Nome"
                                        @input="$v.name.$touch()" 
                                        @blur="$v.name.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="phoneNumber">Contacto Telefónico:</label>
                                    <input 
                                        type="text" 
                                        v-model="phoneNumber" 
                                        class="form-control"
                                        :class="`form-control ${$v.phoneNumber.$error ? 'is-invalid' : ''}`" 
                                        id="phoneNumber" 
                                        placeholder="Contacto Telefónico"
                                        @input="$v.phoneNumber.$touch()" 
                                        @blur="$v.phoneNumber.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="email">Contacto E-mail:</label>
                                    <input 
                                        type="text" 
                                        v-model="email" 
                                        class="form-control"
                                        :class="`form-control ${$v.email.$error ? 'is-invalid' : ''}`" 
                                        id="email" 
                                        placeholder="Contacto E-mail"
                                        @input="$v.email.$touch()" 
                                        @blur="$v.email.$touch()"
                                    >
                                </div>                          
                            </div>
                            <hr>
                            <button type="submit" class="btn btn-primary">Adicionar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>
<script>
    import { validationMixin } from 'vuelidate'
    import { required, email } from 'vuelidate/lib/validators'
export default {
   name:'SupplierAdd',
   mixins: [validationMixin],
   validations: {
        name: { required },
        phoneNumber:{ required },
        email: { required, email },
    },
    data() {
        return {
            name: '',
            phoneNumber: '',
            email: '',
        }
    },
   methods:{
        onSubmit()
        {
            let suppliers = JSON.parse(localStorage.getItem('suppliers'));
            this.$v.$touch()
            if (!this.$v.$invalid) {
                suppliers.push({Nome: this.name, 'Contacto Telefónico': this.phoneNumber, 'Contacto E-mail': this.email})
                localStorage.setItem('suppliers', JSON.stringify(suppliers));
                this.$router.push({name:'app.suppliers-list'})
            }
        }
    }
}
</script>